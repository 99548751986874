@import "../../index.scss";

.navbar{
    display: flex;
    background: $color-back;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    

}

.navbar-links{
    flex:1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s linear;
    color: whitesmoke;
    &:hover{
        color:whitesmoke;
    }
   
}
.navbar-links_container :link{
    &:visited{
        
        color: #fff;
    }
}


.navbar-links_logo{
    margin-right: 3rem;
    
}

.navbar-links_logo img{
    width: 90px;
    height: 62.7px;
    margin-left: 5rem;
    
}
.navbar-links_container ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-top: 15px;
    
    
}

.navbar-links_container ul li,
.navbar_menu_container-links ul li {
    justify-content: center;
    font-family: $font-family;
    color: $color-header;
    font-weight: 300;
    font-size: 1rem;
    line-height: 25px;
    text-transform:capitalize;
    margin: 0 1rem;
    cursor: pointer;
    transition: $mainTransition;
    
    
}
.navbar-links_container ul li
:hover{
   color:whitesmoke;
}
.navbar_menu_container-links ul li
 :hover{
   color:whitesmoke;
}


.navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
    color: $color-header;
    
}
.navbar_menu svg{
    cursor: pointer;
   
}
.navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: $color-back;
    padding: 2rem;
    position: absolute;
    top: 20%;
    right: 10%;
    margin-top: 2rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    transition: $mainTransition;
    &:hover{
        color:$color-header;
    }
   


}
.navbar_menu_container-links ul li{
    margin: 1rem 0;
    list-style: none;
    padding-top: 5px;
    padding-right: 150px;
    
}
@media  screen and (min-width: 1050px) {
    .navbar_menu{
        display: none;
    }
    
}

@media  screen and (max-width: 1050px) 
{
    
    .navbar-links_container{
        display: none;
    }
    .navbar_menu{
        display: flex;
        transition: $mainTransition;
    &:hover{
        color:$color-header;
    }

    }
    
}
@media screen and (max-width: 700px) {
    .navbar{
        padding: 2rem 4rem;
    }
    .navbar-links_logo img{
        width: 90px;
        height: 62.7px;
        margin-left: 0.5rem;
        
    }
}

@media screen and (max-width:550px){
    .navbar{
        padding: 2rem;
    }
    

    .navbar_menu_container{
        top: 20px;
        
    }
    
}

