.ref{
    max-width: 2560;
    margin: 0 auto;
}

.bg-image{
    width: 100%;
    padding-top: 42%;
    background: url(../../assets/deri.jpg) no-repeat center;
    background-size: cover;
}
.refs{
    margin: 30px;
    padding: 20px;
}
.grid_brand{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px,5fr));
    grid-gap: 25px;
    align-items: center;
    justify-items: center;
   

}


.grid-item img{
    -webkit-filter: grayscale(0) blur(0);
	filter: grayscale(0) blur(0);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    &:hover{
        -webkit-filter: grayscale(100%) blur(3px);
        filter: grayscale(100%) blur(3px);
        }
}

@media screen and (max-width: 900px){
    .grid_brand{
        
        grid-template-columns: repeat(auto-fit, minmax(200px,3fr));
        grid-gap: 10px;
       
    
    }
}