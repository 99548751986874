.adresmain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
    line-height: 0.5;
    font-size: 0.8rem;
    color: #3b3b3b;
    padding-inline-start: 20%;
    padding-inline-end: 10%;
       
}

.web{
    text-align: left;
    align-items: left;
    justify-content: left;
    p,a{
        text-align: left;
    }
}

.fabrika p, .toptan p{
    text-align: left;
}

@media screen and (max-width:1024px){
    .adresmain{
        flex-direction: column;
       justify-content: left;
       text-align: left;
    }
    .fabrika .iconss .toptan{
        text-align: left;
    }
}

@media screen and (max-width:550px){
    .adresmain{
        flex-direction: column;
    }
}