

.grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px,3fr));
    grid-gap: 0;
   
   
    
}
.grid-items{
    width: 100%;
}

.grid-items img{
    width: 100%;
    filter: grayscale(1);
    object-fit: cover;
    z-index: 0;
    
    &:hover{
        filter: grayscale(0) opacity(1);
        transition: all 200ms ease-in;
        cursor: pointer;
    
    }
  

}



 .video{
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    object-fit: cover;
    
  }
  .slogan{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    margin-top: 0;
    align-self:flex-end;
    background:#bdb6b6;
}
.sloganlar{
    display: flex;
    margin: auto;
    flex-direction: row;
    margin-top: 4rem;
    align-self: center;
    align-items:baseline;
    
    .grup{
       
        
    .cizgi{
        color:  rgba(147, 130, 125, 1);
        background-color: rgba(147, 130, 125, 1);
        height: 2px;
        
       
    }

    .cizgi2{
        color: rgba(78, 63, 58, 1);
        background-color: rgba(78, 63, 58, 1);
        height: 5px;
        
    }
     .cizgi3{
        color: rgba(166, 125, 125,1);
        background: rgba(166, 125, 125,1);
        height: 2px;
    } 
}
.slogan1 p{
    color: rgb(147, 130, 125);
    letter-spacing: -0.03em;
    font-weight: 300;
    font-size: 36px;
    

}
.slogan2 p{
    color: rgba(78, 63, 58, 1);
    padding-left:0.5rem;
    padding-right: 0.5rem;
    font-family: 'Inter';
    font-weight: 300;
    font-size: 36px;
    letter-spacing: -0.03em;


}
.slogan3{
    
    color: rgb(166, 125, 125);
    font-family: 'Inter';
    font-weight: 300;
    font-size: 36px;
    letter-spacing: -0.03em;

}
}
.blok{
    font-size: 1rem;
    column-count: 2;
    column-gap: 120px;
    column-width: 120px;
    text-align:justify;

}
.blok p span{
    font-size:2rem ;
    opacity: 50%;
    

}
.text {
    
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    max-width: 900px;
    margin: auto;
    color: rgba(78, 63, 58, 1);
    font-family: 'Inter';
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 0.14em;
    padding-inline-start: 50px;
    padding: 30px;

 
    
}
@media screen and (max-width: 1050px){
    .slogan{
        
    
    }
    
}

@media screen and (max-width: 700px){
   
    // .sloganlar{
    //     flex: 1;
    //     flex-direction: column;
    //     margin-top: 3rem;
    //     align-items: center;
    //     line-height: 25px;
        
    
   
    // }
    .text{
        flex: 1;
        flex-direction: row;
        padding: 15px;
        justify-content: center;
        text-align: center;
       
    }
    .cizgi {
        display: none;
    }
    .cizgi2 {
        display: none;
    }
    .cizgi3 {
        display: none;
    }
}

@media screen and (max-width: 550px){
    .video{
        height: 50%;
       
    }
    .slogan{
        height: 50%;
        padding: 25px;
        
    }
    .cizgi {
        display: none;
    }
    .cizgi2 {
        display: none;
    }
    .cizgi3 {
        display: none;
    }
    .blok, .p span{
        column-count: 1;
        align-items: center;
        justify-content: center;

    }
} 