@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@1,300&family=Inter:wght@200;300;400;500;600&display=swap');

*{
  margin:0%;
  padding:0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{

  $font-family: 'Inter', sans-serif;

}
a{
  color:unset;
  text-decoration: none;
}

$color-main:#1f1f1f;
$color-footer:#4E3F3A;
$color-text:#83675D;
$color-header:#bdb6b6;
$color-border:#A38A81;
$color-focus:#C6ABAB;
$color-back:#3b3b3b;

$font-family: 'Inter', sans-serif;
$mainTransition:all 0,3s linear;

@media screen and (max-widht:700px) {
  .section__padding{
      padding:4rem 2rem;

  }

  .section__margin{
      margin:4rem 2rem;
  }
}

.section__padding{
  padding: 4rem 6rem;
}

.section__margin{
  margin:4rem 6rem;
}

.scale-up-center {
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
-webkit-transform: scale(0.5);
        transform: scale(0.5);
}
100% {
-webkit-transform: scale(1);
        transform: scale(1);
}
}

@keyframes scale-up-center {
0% {
-webkit-transform: scale(0.5);
        transform: scale(0.5);
}
100% {
-webkit-transform: scale(1);
        transform: scale(1);
}
}