.swiper {
   width: 100%;
   height: 100%;
 }
 
 .swiper-slide {
   text-align: center;
   font-size: 18px;
   background: #fff;
 
   /* Center slide text vertically */
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
 }
 
 .swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 
 .slogans{
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  align-items: center;
  justify-content: center;
 }

 .slogan11{
  color: rgba(78, 63, 58, 1);
  font-family: 'Inter';
  font-weight: 300;
  font-size: 30px;
  letter-spacing: -0.03em;
  text-align: center;
  justify-content: center;


}
.slogan22{
    
  color: rgb(166, 125, 125);
  font-family: 'Inter';
  font-weight: 300;
  font-size: 30px;
  letter-spacing: -0.03em;
  text-align: center;
  justify-content: center;

}
.resim{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img{
    padding-left: 10px;

  }
}
.fon{

width: 100%;
height: 240px;
margin-top: -100px;
z-index: -1000;
background: rgba(230, 230, 230, 0.4);
}
.showroom p{
  color: #505050;
  font-family: 'Inter';
  font-weight: 300;
  font-size: 40px;
  letter-spacing: 0.5em;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-top: -130px;
  padding-right: 0;
 padding-left: 5px;

}

.grid-urun{
  // max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, minmax(500px,1fr));
  grid-gap: 25px;
  align-items: center;
  justify-items: center;
}
.grid-uruns{
  -webkit-filter: grayscale(0) blur(0);
	filter: grayscale(0) blur(0);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    &:hover{
        -webkit-filter: grayscale(100%) blur(3px);
        filter: grayscale(100%) blur(3px);
        }
}.gr1{
  display: flex;
  flex-direction: row;
  flex: 1;
}
.gr2{
  display: flex;
  flex-direction: row;
  flex: 1;
}
// .alice-carousel img{
//   height: 450px;
  
//   object-fit:contain;
// }
.alice-carousel{
  top:55px;
  padding-bottom: 70px;
}

@media screen and (max-width: 900px){
  .resim{
        flex-direction: column;
      img{
        padding-bottom: 10px;
        padding-top: 10px;
      }
      }

  
  p{
    text-align: center;
  }
}