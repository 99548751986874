.footer{
    background-color: #3b3b3b;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 212px;
    justify-content: space-between;
    width: 100%;
}
.brand{
    flex-direction: row;
}
.brand img{
    margin-top:4rem;
    margin-left: 3rem;
    height: 40px;
    
}
.derux {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 100;
    flex-direction: row;
    position: absolute;
    padding-top: 160px;
    padding-left: 3rem;

    
}
.akyol{
    display: flex;
    flex-direction: row;
    position: relative;
    padding-top: 3rem;
    
    padding-right: 50px;
    align-items: center;
    
    img{
        width: 102px;
        height: 70px;
    }
    p{
        color: #fff;
        font-size: 0.8rem;
        font-weight: 100;
        text-align: right;
        padding-left: 15px;
        padding-top: 5px;
    }
    
}

.adres{
    display: flex;
    flex-direction: column;
    
    .icons{
        font-size: 1rem;
        color: #fff;
        display: flex;
        justify-content: space-around;
        width: 100%;
        position: relative;
        margin-top: 0rem;
        padding-left: 170px;
        
    }
}
.phone{
    font-size: 0.8rem;
    padding-left: 110px;
    color: #fff;
    position: absolute;
    margin-top: 15px;
    padding-top: 7px;
}
.bar{
    width: 250px;
    height: 1px;
    background-color: #fff;
    margin-left: 10px;
    margin-right: -100px;

}
@media  screen and (max-width: 1050px) 
      {
          .footer{
              flex-direction: row-reverse;
              
          }
          .bar{
              display: none;
          }
          .brand{
              flex-direction: row;
             padding-right: 25px;
              margin-right: 0px;
              margin-left: 0rem;
              
          }
          
          .brand img{
            
            height: 25px;
            
            }
         .derux{
            padding-right: 25px;
            padding-top: 120px;
            position: absolute;
            margin-bottom: 10px;
            
         }
         .akyol{
             padding: 25px;
             
         }
         .adres{
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            
            }
        p{
                text-align: right;
                padding-right: 5px;
                padding-top: 10px;
            }
        .icons{
                padding-left: 150px;
            }
    }


@media screen and (max-width: 768px) {

    .footer{
        flex-direction: column-reverse;
        height: 275px;
        align-items: center;
    }
    .brand{
        flex-direction: column;
       align-items: center;
        justify-content: center;
        //padding-inline-start: 90px;
        position: relative;
        
        
    }
    .brand img{
        align-items: center;
        justify-content: center;
        height: 30px;
        margin-bottom: 30px;
        margin-top: 10px;
       

}
.derux {
    display: none;
    font-size: 0.6rem;
    flex-direction: column;
    margin-top: 190px;
   
  
}


.adres{
    display: flex;
    flex-direction: column;
    
    .icons{
       
        justify-content: space-between;
        width: 100%;
        position: relative;
        margin-top: 0rem;
        padding-right: 0px;
        
    }
}
.phone{
    font-size: 0.8rem;
    color: #fff;
    position: absolute;
    margin-top: 15px;
    padding-top: 7px;
}
.bar{
   display: none;

}
.akyol{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    
    img{
        width: 102px;
        height: 70px;
    }
  
}}

@media screen and (max-width:550px){
    .footer{
        flex-direction: column-reverse;
    }
    .brand{
       
        width:max-content;
        position: relative;
      
    }
    .adres{
        width: max-content;
      
    }
   

}