@import "./index.scss";

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

}

a{
  color:unset;
  
}

.lang{
  list-style-type: none;
  flex-direction:row;
  border-style: none;
  background: none;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  position: relative;
  color: $color-header;
  
}
.lang
:hover{
   color:whitesmoke;
}

.language-select{
  
  text-decoration: none;
  position:absolute;
  z-index: 1;
	display: block;
	top:3rem;
	right: 11rem;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  
    &:hover{
        color:$color-focus;
    }
}
@media  screen and (max-width: 1050px) 
      {
        .lang{
        margin: 0.3rem;
        }
        .language-select{
          right: 6rem;
        }
          
      }
@media screen and (max-width: 700px) {
  .lang{
    margin: 0.3rem;
  }
  .language-select{
    right: 6rem;
  }
}

@media screen and (max-width:550px){
  .lang{
    margin: 0.3rem;
    
  }
  .language-select{
    right: 6rem;
  }
    
}