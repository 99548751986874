@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@1,300&family=Inter:wght@200;300;400;500;600&display=swap');


.kurumsal{
    background-color: #3b3b3b;
}
.first-section{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


// .title{
//     display: flex;
//     flex-direction: row;
//     padding: 2rem;
//     padding-left: 8rem;
//     justify-content: space-between;


// img{
//     width:145px;
//     height: 100px;
//     position: relative;
// }
// h1{
    
//     color: #77645D;
//     font-family: inria sans-serif;
//     font-family: 'Inria Serif';
//     font-style: italic;
//     font-weight: 300;
//     font-size: 32px;
//     line-height: 62px;
//     /* identical to box height, or 194% */
//     letter-spacing: 0.19em;
//     align-items: flex-end;
//     justify-content: left;
// }

// }
// .bina1{
//     flex:1;
//     display:flex;
//     justify-content:center;
//     align-items:flex-start;
    
    
// }
// .bina1 img{
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
   
// }
.content{
    margin: auto;
    padding: 100px;
    
}
.content{
   
    p{
        
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 27px;
        text-align: justify;
        letter-spacing: 0.06em;
        color: whitesmoke;
        max-width: 1000px;
        

    }
}
.alt-bolum{
    display: flex;
    flex-direction: row;
    align-items: center;

    
}

.brand2{
    
    align-items: center;
    justify-content: space-between;
    align-items:center;
    margin: auto;
    img{
        padding-bottom: 20px;
        padding-top: 30px;
        padding-right: 40px;
    }
    
}
.prensip{
    max-width: 790px;
    display: grid ;
    grid-template-columns: repeat(auto-fit, minmax(385px,1fr));
    margin:auto;
    column-gap: 20px;
        
   
    
    
}
.baslik{
    text-align: center;
    padding-top: 20px;
    color: #3b3b3b;
    font-weight: 300;
}
.metin{
    font-size: 1rem;
    color: #3b3b3b;
    font-weight: 200;
    margin:50px 80px;
    justify-content: baseline;
    text-align: justify;

}

.kutu{
  
  width:385px;
  height: 268px;
  /* center content using flex box */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overlay */
  box-shadow: 0 0 0 200px inset, 0 0 5px grey;
  /* hover out transition */
  transition: box-shadow 1s;
  margin: 10px;
  padding-top: 0px;
  
 
  
}
.kutu img{
    position: absolute;
    z-index: 0;
    opacity: 0.6;
   
   
}
.kutu::after{
    white-space: pre;
    width: 40%;
    height: 80%;
    display: block;
    font-size: 2.5rem;
    line-height: 24px;
    font-weight: 400;
    font: 'Inter', sans-serif;
    color:rgba(126, 195, 81, 1);
    border: 2px solid;
    text-align: center;
    /* center content using flex box */
    display: flex;
    justify-content: center;
    align-items:flex-end;
    /* hover out transition */
    transition: opacity 1s .5s;
    color: rgba(126, 195, 81, 1);
    
    
   
    
    
}
.kutu:hover{
    color: rgba(126, 195, 81, 1);
    box-shadow: 0 0 0 0px inset;
    cursor:help;  
    h1{
        display: none;
    }

    p{
        display: block;
    }
    img{
        display: none;
    }
 
    
}
.kutu:hover::after {
    opacity: 0;
    
   
   // content: " ";
   
}
.kutu h1{
    color: whitesmoke;
    justify-content: center;
    align-items: center;
    z-index:1;
    
}
#misyon{
    color: rgba(80, 80, 80, 1);
    background-color: rgba(126, 195, 81, 1);
    display: flex;
    flex-direction: column;
   
}
#misyon::after{
    //content:'"Misyon"';
    color: whitesmoke;
    .kutu h1{
        display: block;
    }
}
#misyon:hover::before{
    cursor:help;
  
}
#vizyon{
    color: rgba(80, 80, 80, 1);
    background-color: rgba(126, 195, 81, 1);
    display: flex;
    flex-direction: column;
   
}
#vizyon::after{
    //content:'"Misyon"';
    color: whitesmoke;
    .kutu h1{
        display: block;
    }
}
#vizyon:hover::before{
    cursor:help;
  
}
#faaliyet{
    color: rgba(80, 80, 80, 1);
    background-color: rgba(126, 195, 81, 1);
    display: flex;
    flex-direction: column;
   
}
#faaliyet::after{
    //content:'"Misyon"';
    color: whitesmoke;
    .kutu h1{
        display: block;
    }
}
#faaliyet:hover::before{
    cursor:help;
  
}
#sürdürülebilirlik{
    color: rgba(80, 80, 80, 1);
    background-color: rgba(126, 195, 81, 1);
    display: flex;
    flex-direction: column;
   
}
#sürdürülebilirlik::after{
    //content:'"Misyon"';
    color: whitesmoke;
    .kutu h1{
        display: block;
    }
}
#sürdürülebilirlik:hover::before{
    cursor:help;
  
}

.kutu p{
    align-items: center;
    text-align: center;
    justify-content:flex-start;    
   //content: "Tüm dünya pazarı için kalite deri kemer üretmek,tedarikçi ve müşterileriyle birlikte yüksek değer yaratmak";
    font-size: 1.2rem;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: whitesmoke;
    display: none;
    
   

}
@media screen and (max-width: 900px){
    .prensip{
        
        align-content: center;
        // grid-template-columns: repeat(auto-fit, minmax(390px,1fr));
        justify-content: center;
        // column-gap: 10px;
        
       
    }
  
    .brand2{
    
       display: flex;
       flex-direction: column;
       padding-left: 20px;
       
        
    }
   

}

@media screen and (max-width: 550px){
    .prensip{
        
        // grid-template-columns: repeat(auto-fit, minmax(390px,1fr));
        padding-bottom: 50px;
        column-gap:30px;
        
        
    }
  
    .brand2{
    
       display: flex;
       flex-direction: column;
        
    }

}